<template>
  <div>
    <b-card>
      <progress-bar-status :progress="1" :status="currentDataDoctor.status" />
    </b-card>

    <div class="bg-white rounded mt-3 mx-0">
      <ViewDoctorQuestionnaire
        :registration_id="currentDataDoctor.registration_id"
        :editable="false"
      />
    </div>

    <div class="bg-white rounded mt-3 p-5 mx-0">
      <div class="d-flex align-items-start">
        <h2>Hasil Pemeriksaan Dokter</h2>
        <hr />
        <b-button @click="toggleEdit" class="mb-3">
          {{ isEditing ? "Batal" : "Edit" }}
        </b-button>
        <b-button
          v-if="isEditing"
          @click="saveChanges"
          class="mb-3 ml-2"
          variant="success"
          :disabled="isBusy"
        >
          <b-spinner small v-if="isBusy" />
          Simpan
        </b-button>
      </div>
      <b-form @submit.prevent="saveChanges">
        <b-row>
          <b-col>
            <b-form-group label="Status">
              <b-form-checkbox
                v-model="editableData.status"
                :disabled="!isEditing"
                switch
              >
                {{ editableData.status ? "Diterima" : "Ditolak" }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Tekanan Darah">
              <b-form-input
                v-model="editableData.blood_pressure"
                :disabled="!isEditing"
                placeholder="mmHg"
              />
            </b-form-group>
            <b-form-group label="Denyut Nadi">
              <b-form-input
                v-model="editableData.pulse"
                :disabled="!isEditing"
                placeholder="bpm"
              />
            </b-form-group>
            <b-form-group label="Berat Badan">
              <b-form-input
                v-model="editableData.weight"
                :disabled="!isEditing"
                placeholder="Kg"
              />
            </b-form-group>
            <b-form-group label="Tinggi Badan">
              <b-form-input
                v-model="editableData.height"
                :disabled="!isEditing"
                placeholder="cm"
              />
            </b-form-group>
            <b-form-group label="Keadaan Umum">
              <b-form-input
                v-model="editableData.general_condition"
                :disabled="!isEditing"
              />
            </b-form-group>
            <b-form-group label="Suhu">
              <b-form-input
                v-model="editableData.temperature"
                :disabled="!isEditing"
                placeholder="°C"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Riwayat Medis">
              <b-form-textarea
                v-model="editableData.medical_history"
                :disabled="!isEditing"
                rows="3"
              />
            </b-form-group>
            <b-form-group label="Metode Pengambilan Darah">
              <b-form-select
                v-model="editableData.collection_method"
                :disabled="!isEditing"
                :options="collectionMethods"
              />
            </b-form-group>
            <b-form-group label="Kantong">
              <b-form-select
                v-model="editableData.bag"
                :disabled="!isEditing"
                :options="bagTypes"
              />
            </b-form-group>
            <b-form-group label="Diambil Sebanyak">
              <b-form-radio-group
                v-model="editableData.cc"
                :options="ccOptions"
                :disabled="!isEditing"
              />
            </b-form-group>
            <b-form-group
              label="Alasan Penolakan"
              v-if="editableData.status === false"
            >
              <b-form-select
                v-model="editableData.reason_rejected"
                :disabled="!isEditing"
                :options="[
                  { text: 'Tensi Rendah', value: 'tensi rendah' },
                  { text: 'Tensi Tinggi', value: 'tensi tinggi' },
                  { text: 'Sedang Menyusui', value: 'sedang menyusui' },
                  { text: 'Operasi Gigi', value: 'operasi gigi' },
                  { text: 'Alergi', value: 'alergi' },
                  { text: 'Lain-lain', value: 'lain-lain' },
                ]"
              />
            </b-form-group>
            <b-form-group
              label="Catatan"
              v-if="editableData.reason_rejected === 'lain-lain'"
            >
              <b-form-textarea
                v-model="editableData.note"
                :disabled="!isEditing"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BTable,
  BPagination,
  BSpinner,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
  BFormRadioGroup,
} from "bootstrap-vue";
import ProgressBarStatus from "../../components/common/ProgressBarStatus.vue";
import manageDoctorAPI from "../../../../../../api/managedoctor/manageDoctorAPI";
import ViewDoctorQuestionnaire from "../../components/common/docter/ViewDoctorQuestionnaire.vue";

export default {
  name: "ViewDonationStatus",
  components: {
    BCol,
    BRow,
    BTable,
    BPagination,
    BSpinner,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BFormRadioGroup,
    ProgressBarStatus,
    ViewDoctorQuestionnaire,
  },
  data() {
    return {
      labs: ["Registrasi", "Dokter", "HB", "Aftaf"],
      currentDataDoctor: null,
      editableData: {},
      routeName: "",
      isBusy: false,
      isEditing: false,
      collectionMethods: [
        { value: "Biasa", text: "Biasa" },
        { value: "Aferesis", text: "Aferesis" },
        { value: "Autologus", text: "Autologus" },
      ],
      bagTypes: [
        { value: "S", text: "S" },
        { value: "DK", text: "DK" },
        { value: "DB", text: "DB" },
        { value: "TR", text: "TR" },
        { value: "QD", text: "QD" },
      ],
      ccOptions: [
        { value: "350", text: "350 cc" },
        { value: "450", text: "450 cc" },
      ],
    };
  },
  mounted() {
    this.getDoctor();
  },
  methods: {
    async getDoctor() {
      try {
        this.isBusy = true;
        const { data } = await manageDoctorAPI.getDetail(this.$route.params.id);
        this.currentDataDoctor = data.data;
        this.editableData = { ...this.currentDataDoctor };
        this.editableData.status = this.editableData.status === "Done";
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.editableData = { ...this.currentDataDoctor };
        this.editableData.status = this.editableData.status === "Done";
      }
    },
    async saveChanges() {
      try {
        this.isBusy = true;
        const dataToSave = {
          ...this.editableData,
          status: this.editableData.status ? "Done" : "Rejected",
        };
        if (dataToSave.status !== "Done") {
          if (!dataToSave.reason_rejected) {
            throw new Error("Reason rejected harus diisi");
          }
          if (dataToSave.reason_rejected === "lain-lain" && !dataToSave.note) {
            throw new Error("Note wajib diisi");
          }
          dataToSave.note = dataToSave.note || "";
          dataToSave.reason_rejected = this.editableData.reason_rejected;
        } else {
          dataToSave.reason_rejected = "";
          dataToSave.note = "";
        }
        // Implement your API call to save changes here
        await manageDoctorAPI.edit(dataToSave);
        this.currentDataDoctor = { ...dataToSave };
        this.isEditing = false;
        // Show success message toast
        this.$bvToast.toast("Perubahan berhasil disimpan", {
          title: "Sukses",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        // Show error message toast
        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
#progress {
  -webkit-appearance: none;
  position: absolute;
  width: 95%;
  z-index: 5;
  margin-left: 22px;
  margin-bottom: 44px;
}
</style>
